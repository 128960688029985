import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './wechat-user.reducer';
import { IWechatUser } from 'app/shared/model/wechat-user.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IWechatUserDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const WechatUserDetail = (props: IWechatUserDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { wechatUserEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="examsysApp.wechatUser.detail.title">WechatUser</Translate> [<b>{wechatUserEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="nickName">
              <Translate contentKey="examsysApp.wechatUser.nickName">Nick Name</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.nickName}</dd>
          <dt>
            <span id="avatarUrl">
              <Translate contentKey="examsysApp.wechatUser.avatarUrl">Avatar Url</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.avatarUrl}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="examsysApp.wechatUser.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.gender}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="examsysApp.wechatUser.country">Country</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.country}</dd>
          <dt>
            <span id="province">
              <Translate contentKey="examsysApp.wechatUser.province">Province</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.province}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="examsysApp.wechatUser.city">City</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.city}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="examsysApp.wechatUser.language">Language</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.language}</dd>
          <dt>
            <span id="openid">
              <Translate contentKey="examsysApp.wechatUser.openid">Openid</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.openid}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="examsysApp.wechatUser.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {wechatUserEntity.createDate ? (
              <TextFormat value={wechatUserEntity.createDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="token">
              <Translate contentKey="examsysApp.wechatUser.token">Token</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.token}</dd>
          <dt>
            <span id="tel">
              <Translate contentKey="examsysApp.wechatUser.tel">Tel</Translate>
            </span>
          </dt>
          <dd>{wechatUserEntity.tel}</dd>
        </dl>
        <Button tag={Link} to="/wechat-user" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/wechat-user/${wechatUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ wechatUser }: IRootState) => ({
  wechatUserEntity: wechatUser.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WechatUserDetail);
