import { Moment } from 'moment';

export interface IWechatUser {
  id?: number;
  nickName?: string;
  avatarUrl?: string;
  gender?: string;
  country?: string;
  province?: string;
  city?: string;
  language?: string;
  openid?: string;
  createDate?: string;
  token?: string;
  tel?: string;
}

export const defaultValue: Readonly<IWechatUser> = {};
