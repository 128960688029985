export interface IZixun {
  id?: number;
  title?: string;
  time?: string;
  isTop?: boolean;
  author?: string;
  content?: any;
}

export const defaultValue: Readonly<IZixun> = {
  isTop: false,
};
