import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
    <div>
        <h3>使用说明</h3>
      <ol>
        <li>试题管理：请点击右上角【数据】中（试题）进行管理</li>
        <li>资讯管理：请点击右上角【数据】中（资讯）进行管理</li>
        <li>授权用户管理：请点击右上角【数据】中（授权用户）进行管理</li>
        <li>请将授权用户的激活码发给用户用于在小程序端登录激活，导入时激活码自动生成，单独新增需自定义激活码</li>
        <li>小程序用户管理：请点击右上角【数据】中（小程序用户）进行管理</li>
        <li>授权用户导入：请点击右上角【数据】中（导入）进行新增导入excel文件，注意表名写成“用户”</li>
        <li>试题类型1（选项在一列中的）导入：请点击右上角数据中（导入）进行新增导入excel文件，注意表名写成“试题1”</li>
        <li>试题类型2（选项在一列中的）导入：请点击右上角数据中（导入）进行新增导入excel文件，注意表名写成“试题2”</li>
      </ol>
    </div>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
