import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AuthUserImport from './auth-user-import';
import AuthUserImportDetail from './auth-user-import-detail';
import AuthUserImportUpdate from './auth-user-import-update';
import AuthUserImportDeleteDialog from './auth-user-import-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AuthUserImportUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AuthUserImportUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AuthUserImportDetail} />
      <ErrorBoundaryRoute path={match.url} component={AuthUserImport} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={AuthUserImportDeleteDialog} />
  </>
);

export default Routes;
