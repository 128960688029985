import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IZixun, defaultValue } from 'app/shared/model/zixun.model';

export const ACTION_TYPES = {
  FETCH_ZIXUN_LIST: 'zixun/FETCH_ZIXUN_LIST',
  FETCH_ZIXUN: 'zixun/FETCH_ZIXUN',
  CREATE_ZIXUN: 'zixun/CREATE_ZIXUN',
  UPDATE_ZIXUN: 'zixun/UPDATE_ZIXUN',
  DELETE_ZIXUN: 'zixun/DELETE_ZIXUN',
  SET_BLOB: 'zixun/SET_BLOB',
  RESET: 'zixun/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IZixun>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type ZixunState = Readonly<typeof initialState>;

// Reducer

export default (state: ZixunState = initialState, action): ZixunState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ZIXUN_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ZIXUN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ZIXUN):
    case REQUEST(ACTION_TYPES.UPDATE_ZIXUN):
    case REQUEST(ACTION_TYPES.DELETE_ZIXUN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ZIXUN_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ZIXUN):
    case FAILURE(ACTION_TYPES.CREATE_ZIXUN):
    case FAILURE(ACTION_TYPES.UPDATE_ZIXUN):
    case FAILURE(ACTION_TYPES.DELETE_ZIXUN):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ZIXUN_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ZIXUN):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ZIXUN):
    case SUCCESS(ACTION_TYPES.UPDATE_ZIXUN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ZIXUN):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
        },
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/zixuns';

// Actions

export const getEntities: ICrudGetAllAction<IZixun> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_ZIXUN_LIST,
  payload: axios.get<IZixun>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IZixun> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ZIXUN,
    payload: axios.get<IZixun>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IZixun> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ZIXUN,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IZixun> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ZIXUN,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IZixun> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ZIXUN,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
