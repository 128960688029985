import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import WechatUser2 from './wechat-user-2';
import WechatUser2Detail from './wechat-user-2-detail';
import WechatUser2Update from './wechat-user-2-update';
import WechatUser2DeleteDialog from './wechat-user-2-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={WechatUser2Update} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={WechatUser2Update} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={WechatUser2Detail} />
      <ErrorBoundaryRoute path={match.url} component={WechatUser2} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={WechatUser2DeleteDialog} />
  </>
);

export default Routes;
