import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './wechat-user.reducer';
import { IWechatUser } from 'app/shared/model/wechat-user.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IWechatUserProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const WechatUser = (props: IWechatUserProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { wechatUserList, match, loading } = props;
  return (
    <div>
      <h2 id="wechat-user-heading">
        <Translate contentKey="examsysApp.wechatUser.home.title">Wechat Users</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="examsysApp.wechatUser.home.createLabel">Create new Wechat User</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {wechatUserList && wechatUserList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.nickName">Nick Name</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.avatarUrl">Avatar Url</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.gender">Gender</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.country">Country</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.province">Province</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.city">City</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.language">Language</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.openid">Openid</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.createDate">Create Date</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.token">Token</Translate>
                </th>
                <th>
                  <Translate contentKey="examsysApp.wechatUser.tel">Tel</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {wechatUserList.map((wechatUser, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${wechatUser.id}`} color="link" size="sm">
                      {wechatUser.id}
                    </Button>
                  </td>
                  <td>{wechatUser.nickName}</td>
                  <td>{wechatUser.avatarUrl}</td>
                  <td>{wechatUser.gender}</td>
                  <td>{wechatUser.country}</td>
                  <td>{wechatUser.province}</td>
                  <td>{wechatUser.city}</td>
                  <td>{wechatUser.language}</td>
                  <td>{wechatUser.openid}</td>
                  <td>
                    {wechatUser.createDate ? <TextFormat type="date" value={wechatUser.createDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                  </td>
                  <td>{wechatUser.token}</td>
                  <td>{wechatUser.tel}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${wechatUser.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${wechatUser.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${wechatUser.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="examsysApp.wechatUser.home.notFound">No Wechat Users found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ wechatUser }: IRootState) => ({
  wechatUserList: wechatUser.entities,
  loading: wechatUser.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WechatUser);
