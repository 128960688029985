export interface IQuestion {
  id?: number;
  category?: string;
  type?: string;
  title?: string;
  option?: string;
  answer?: string;
  explain?: string;
}

export const defaultValue: Readonly<IQuestion> = {};
