import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './wechat-user-2.reducer';
import { IWechatUser2 } from 'app/shared/model/wechat-user-2.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IWechatUser2DetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const WechatUser2Detail = (props: IWechatUser2DetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { wechatUser2Entity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="examsysApp.wechatUser2.detail.title">WechatUser2</Translate> [<b>{wechatUser2Entity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="nickName">
              <Translate contentKey="examsysApp.wechatUser2.nickName">Nick Name</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.nickName}</dd>
          <dt>
            <span id="avatarUrl">
              <Translate contentKey="examsysApp.wechatUser2.avatarUrl">Avatar Url</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.avatarUrl}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="examsysApp.wechatUser2.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.gender}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="examsysApp.wechatUser2.country">Country</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.country}</dd>
          <dt>
            <span id="province">
              <Translate contentKey="examsysApp.wechatUser2.province">Province</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.province}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="examsysApp.wechatUser2.city">City</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.city}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="examsysApp.wechatUser2.language">Language</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.language}</dd>
          <dt>
            <span id="openid">
              <Translate contentKey="examsysApp.wechatUser2.openid">Openid</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.openid}</dd>
          <dt>
            <span id="createDate">
              <Translate contentKey="examsysApp.wechatUser2.createDate">Create Date</Translate>
            </span>
          </dt>
          <dd>
            {wechatUser2Entity.createDate ? (
              <TextFormat value={wechatUser2Entity.createDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="token">
              <Translate contentKey="examsysApp.wechatUser2.token">Token</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.token}</dd>
          <dt>
            <span id="tel">
              <Translate contentKey="examsysApp.wechatUser2.tel">Tel</Translate>
            </span>
          </dt>
          <dd>{wechatUser2Entity.tel}</dd>
        </dl>
        <Button tag={Link} to="/wechat-user-2" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/wechat-user-2/${wechatUser2Entity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ wechatUser2 }: IRootState) => ({
  wechatUser2Entity: wechatUser2.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WechatUser2Detail);
