import { Moment } from 'moment';

export interface IAuthUserImport {
  id?: number;
  tel?: string;
  createDate?: string;
  startDate?: string;
  endDate?: string;
  code?: string;
}

export const defaultValue: Readonly<IAuthUserImport> = {};
